import { decodeHTMLString } from './decode-markdown';
import type { MetaArgs } from '@remix-run/node';
import { getRouteData } from '~/modules/root/util/get-route-data';

export const createMetaTitle = (
    props: MetaArgs,
    links: (string | undefined | null)[],
    countrySuffix = true,
    pageNumber = 1,
    includePostfix = true
) => {
    const { matches } = props;
    const { rootData } = getRouteData(matches);

    const titlePostfix = `ArchiPro ${rootData?.localisation.country || ''}`;
    if (!links || links.length === 0) return titlePostfix;

    const title = links.find((exists) => exists);
    if (!title) return titlePostfix;

    const pageNumberPrefix = pageNumber > 1 ? `Page ${pageNumber} - ` : '';

    if (title.search('undefined') !== -1 && import.meta.env.DEV) {
        console.warn('Page title has undefined value');
    }

    return decodeHTMLString(
        countrySuffix
            ? `${pageNumberPrefix}${title}` +
                  (includePostfix ? ` | ${titlePostfix}` : '')
            : `${title}`
    );
};
